
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email, max, min } from "vee-validate/dist/rules";
// import { ReferralClientApi } from "@/api/server";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("email", {
  ...email,
  message: "A valid email is required",
});
extend("max", {
  ...max,
  message: "The max value has been exceeded",
});
extend("min", {
  ...min,
  message: "Please input a larger value",
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Child extends Vue {
  // private client!: ReferralClient;
  public countries = [];
  public regions = [];
  private loading = false;
  public menu = false;
  private readonly maxDob = new Date().toDateString();
  private readonly gender_options = [
    { text: "Male", value: "M" },
    { text: "Female", value: "F" },
  ];
  public editedItem = {
    child: {
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      dob: "",
      address: {},
      guardian: {},
      provider: {
        address: {},
      },
      source: {
        address: {},
      },
    },
  };

  public filterRegions(): boolean {
    return false;
  }
  // @Prop()
  // public value!: ReferralClient.IReferralClient;
  // private get editedItem(): ReferralClientApi.IReferralClient {
  //     return !!this.value ? this.value : new Child();
  // }
}
